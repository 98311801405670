import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'
import { jsonapiClient } from 's4n-jsonapi-client'

import { wishlistFetch } from './wishlist'

const suppressException = process.env.SOFT4NET_CREATE_ACTION_THUNK_SUPPRESS_EXCEPTION === `true` ? true : false;

export const userRegister = createActionThunk('USER_REGISTER', async (formData, store) => {
    const state = store.getState();
    // const { order: { items, included } } = state.shopReducers;

    const { intl } = state.intl;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'user_register', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            formData, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)

export const userPasswordReset = createActionThunk('USER_PASSWORD_RESET', async (formData, store) => {
    const state = store.getState();
    // const { order: { items, included } } = state.shopReducers;

    const { intl } = state.intl;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'user_password_reset', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            formData, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)

export const userPasswordUpdate = createActionThunk('USER_PASSWORD_UPDATE', async (authorization, store) => {
    const state = store.getState();
    // const { order: { items, included } } = state.shopReducers;

    const { intl } = state.intl;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'user_password_update', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)

// MUST BE AS LAST IN THIS FILE SO WE CAN INVOKE ABOVE ACTIONS???
// export const userLogin = createAction('USER_LOGIN')
export const userLogin = createActionThunk('USER_LOGIN', async (authorizationToken, store) => {
    const state = store.getState();

    // last resort of trying to have authorization token
    if (!authorizationToken) {
        authorizationToken = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;
    }

    // @todo: Refactor - do not pass token here it can be obtained within action too
    if (authorizationToken) {
        store.dispatch(userProfile(authorizationToken));
        store.dispatch(wishlistFetch(authorizationToken));
        store.dispatch(profileFetch(authorizationToken));
    }

    // we must return payload to reducer!!!
    return { data: null };

}, suppressException)

export const userLogout = createAction('USER_LOGOUT')

export const userProfile = createActionThunk('USER_PROFILE', async (authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const { data: users } = await jsonapiClient(process.env.REACT_APP_API_URL, 'user_profile', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });



    // return { data: users };



    // console.log(users)

    const extractLoggedInUser = (users) => {
      if (!users || users.length === 0) {
        return null;
      }

      const loggedInUser = users.filter(user => user.attributes && user.relationships).shift();
// console.log(loggedInUser)
      return loggedInUser;
    }

    return { data: extractLoggedInUser(users) };

}, suppressException)



// @todo: refactor, REST Endpoint!!! THIS SHOULD NOT BE HERE SINCE IT IS ONLY JSONAPI API
export const simplenewsNewsletter = createActionThunk('SIMPLENEWS_NEWSLETTER', async (authorization, simplenews_newsletter, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const simplenewsNewsletter = await jsonapiClient(process.env.REACT_APP_API_URL, 'simplenews_newsletter', {
        parameters: {
            authorization, // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
            simplenews_newsletter,
        },
        options: {
            langcode: intl.locale
        },
    });

    return simplenewsNewsletter;
}, suppressException)

// @todo: refactor, REST Endpoint!!! THIS SHOULD NOT BE HERE SINCE IT IS ONLY JSONAPI API
export const simplenewsSubscriber = createActionThunk('SIMPLENEWS_SUBSCRIBER', async (authorization, method, status, mail, subscriptions, store) => {

    const state = store.getState();
    const { intl } = state.intl;
// console.log(subscriptions)

    const simplenews_subscriber = await jsonapiClient(process.env.REACT_APP_API_URL, 'simplenews_subscriber_get_id', {
        parameters: {
            authorization, // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
            mail,
        },
        options: {
            langcode: intl.locale
        },
    });

    if (null !== simplenews_subscriber && `POST` === method) { // This is the case when "anonymopus" user requests to be added to newsletter but he is already assigned to it
        return { simplenewsSubscriber: null };
    }
    if (null === simplenews_subscriber && `GET` === method) { // This is the case when "authenticated" is not in newsletter while he is sending GET request to check this
        return { simplenewsSubscriber: null };
    }
    // if (null === simplenews_subscriber) {
    //     return { simplenewsSubscriber: null };
    // }

    const simplenewsSubscriber = await jsonapiClient(process.env.REACT_APP_API_URL, 'simplenews_subscriber', {
        parameters: {
            authorization, // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
            simplenews_subscriber,
            method,
            status,
            mail,

            /*
                { target_id: `default`, status: (0|1|2) }
                target_id: name of newsletter = `default`
                
                status::
                define('SIMPLENEWS_SUBSCRIPTION_STATUS_UNSUBSCRIBED', 0);
                define('SIMPLENEWS_SUBSCRIPTION_STATUS_SUBSCRIBED', 1);
                define('SIMPLENEWS_SUBSCRIPTION_STATUS_UNCONFIRMED', 2);
            */
           subscriptions, // { target_id: `default`, status: (0|1|2)} , 0 - unsubscribed, 1 - subscribed, 2 - unconfirmed
        },
        options: {
            langcode: intl.locale
        },
    });

// console.log(simplenewsSubscriber)

    return simplenewsSubscriber;
}, suppressException)



export const profileFetch = createActionThunk('PROFILE_FETCH', async (authorization, store) => {
    const state = store.getState();
    // const { profile: { items, included } } = state.shopReducers;

    const { intl } = state.intl;

    // this Promise returns { wishlist: data, included: included }
    return await jsonapiClient(process.env.REACT_APP_API_URL, 'profile_fetch', {

        // @todo: refactor i think i should not pass authorization here instead move this to api, less params passing all over te better
        parameters: {
            authorization, 
        },
        options: {
            langcode: intl.locale
        },
    });

}, suppressException)

export const profileAdd = createActionThunk('PROFILE_ADD', async (profile, authorization, store) => {
    const state = store.getState();
    // const { wishlist: { items, included } } = state.shopReducers;

    const { intl } = state.intl;

    await jsonapiClient(process.env.REACT_APP_API_URL, 'profile_add', {
        parameters: {
            purchasedEntity: profile,
            authorization,
            // quantity: 1
        },
        options: {
            langcode: intl.locale
        },
    });

    store.dispatch(profileFetch(authorization));

    // we must return payload to reducer!!!
    return { data: [], included: [] };
}, suppressException)

export const profilePatch = createActionThunk('PROFILE_PATCH', async (profile, attributes, authorization, store) => {
    const state = store.getState();
    const { intl } = state.intl;

    const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'profile_patch', {
        parameters: {
            profile,
            attributes,
            authorization,
        },
        options: {
            langcode: intl.locale
        },
    });

    // do we need to do that because response contains updated profiles already!!!
    store.dispatch(profileFetch(authorization));

    // we must return payload to reducer!!!
    return payload; 
}, suppressException)

// THIS ACTION HAS NO REDUCER BECAUSE IT DOES NOT NEED IT
export const profileRemove = createActionThunk('PROFILE_REMOVE', async (profile, authorization, store) => {
    const state = store.getState();
    // const { wishlist: { items, included } } = state.shopReducers;

    const { intl } = state.intl;

    await jsonapiClient(process.env.REACT_APP_API_URL, 'profile_remove', {
        parameters: {
            profile,
            authorization,
        },
        options: {
            langcode: intl.locale
        },
    });

    store.dispatch(profileFetch(authorization));

    // we must return payload to reducer!!!
    // return { data: [], included: [] };
}, suppressException)