import { createAction } from 'redux-actions'
import { createActionThunk } from 'redux-thunk-actions'
import { jsonapiClient } from 's4n-jsonapi-client'

const suppressException = process.env.SOFT4NET_CREATE_ACTION_THUNK_SUPPRESS_EXCEPTION === `true` ? true : false;

export const setCartToken = createAction(('SET_CART_TOKEN'))
export const cartFetch = createActionThunk('CART_FETCH', async (store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'carts', {
    parameters: {
      cartToken
    },
    
    // s4n
    options: { 
        langcode: intl.locale
    },
  });
})
export const cartAdd = createActionThunk('CART_ADD', async (variation, quantity, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'add_to_cart', {
    parameters: {
      cartToken,
      purchasedEntity: variation,
      quantity,
    },
    
    // s4n
    options: { 
        langcode: intl.locale
    },
  });
}, suppressException)
export const cartRemove = createActionThunk('CART_REMOVE', async (orderItem, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  await jsonapiClient(process.env.REACT_APP_API_URL, 'remove_cart_item', {
    parameters: {
      cartToken,
      orderItem,
    },
    
    // s4n
    options: { 
        langcode: intl.locale
    },
  })
  store.dispatch(cartFetch());
})
export const cartUpdateItem = createActionThunk('CART_UPDATE_ITEM', async (orderItem, quantity, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;
  const { intl } = state.intl; // s4n
  return await jsonapiClient(process.env.REACT_APP_API_URL, 'update_cart_item', {
    parameters: {
      cartToken,
      orderItem: orderItem,
      quantity,
    },
    
    // s4n
    options: { 
        langcode: intl.locale
    },
  })
})
export const checkoutChangeStep = createAction('CHECKOUT_CHANGE_STEP');

export const cartAddCoupon = createActionThunk('CART_ADD_COUPON', async (cartId, couponCode, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;

  const { intl } = state.intl; // s4n

  const headers = {
    'Commerce-Cart-Token': cartToken,
    'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json',
  }
  let token = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;
  if (token) {
    headers['Authorization'] = `${token.token_type} ${token.access_token}`;
  }

  const res = await fetch(`${process.env.REACT_APP_API_URL}/${intl.locale}/jsonapi/carts/${cartId}/coupons`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify({
      data: [{
        
        // type: 'promotion-coupon',
        type: `commerce_promotion_coupon--commerce_promotion_coupon`, // s4n

        id: couponCode
      }]
    })
  })
  store.dispatch(cartFetch())
  return res.json();
})



export const cartRemoveCoupon = createActionThunk('CART_REMOVE_COUPON', async (cartId, couponCode, store) => {
  const state = store.getState();
  const { cart: {cartToken} } = state.shopReducers;

  const { intl } = state.intl;

  const headers = {
    'Commerce-Cart-Token': cartToken,
    'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json',
  }
  let token = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;
  if (token) {
    headers['Authorization'] = `${token.token_type} ${token.access_token}`;
  }
  
  const res = await fetch(`${process.env.REACT_APP_API_URL}/${intl.locale}/jsonapi/carts/${cartId}/coupons`, {
    method: 'DELETE',
    headers,
  })
  store.dispatch(cartFetch())
  return res;
  // return res.json();
})
