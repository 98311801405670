import { handleActions } from 'redux-actions';
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import cart from './cart';
import cartFlyout from './cartFlyout'
import productDisplay from './productDisplay'
import wishlist, * as fromWishlist from './wishlist'
import order from './order'
import user from './user'

import { CHECKOUT_STEP_CUSTOMER_INFORMATION } from './../../../s4n/utils/CheckoutSteps'

const isBrowser = typeof window !== 'undefined';

const currentStore = handleActions({
  'SELECT_STORE': (state, { payload }) => {
    isBrowser && localStorage.setItem('currentStoreId', payload)

    return {
      ...state,
      id: payload
    }
  }
}, {
  id: isBrowser && localStorage.getItem('currentStoreId') || process.env.REACT_APP_STORE_UUID,
})

const navigationReducer = handleActions({
  '@@router/LOCATION_CHANGE': (state) => {
    window.scrollTo(0, 0);
    return ({
      ...state,
    })
  },
}, {})


const INITIAL_STATE = {
  orderId: null,
  orderData: null,
  included: [],
  currentStep: CHECKOUT_STEP_CUSTOMER_INFORMATION
};

const checkoutReducer = handleActions({
  '@@router/LOCATION_CHANGE': (state) => ({
    ...state,
    currentStep: CHECKOUT_STEP_CUSTOMER_INFORMATION
  }),
  'CHECKOUT_CHANGE_STEP': (state, { payload }) => ({
    ...state,
    currentStep: payload
  }),
  CHECKOUT_GET_SUCCEEDED: (state, { payload }) => {
    if (typeof payload === `undefined`) {
      return {
        ...state,
        ...INITIAL_STATE,
      }
    }

    return {
      ...state,
      orderId: payload.cart.id,
      orderData: payload.cart,
      included: payload.included,
    }
  },
  CHECKOUT_UPDATE_SUCCEEDED: (state, { payload }) => {
    return {
      ...state,
      orderData: payload.cart,
      included: payload.included,
    }
  }
}, INITIAL_STATE)

export default history => combineReducers({
  
  router: typeof history === 'undefined' ? null : connectRouter(history),
  // router: connectRouter(history),

  cart,
  cartFlyout,
  navigation: navigationReducer,
  checkout: checkoutReducer,
  productDisplay,
  currentStore,

  wishlist,
  order,
  user,
});



// selectors, thank to this export client does not need to know in which file particular selector is stored !!!
export const selectWishlistItemProductVariations = (state) => fromWishlist.selectWishlistItemProductVariations(state.shopReducers.wishlist);