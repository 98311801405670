/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { useIntl } from "gatsby-plugin-intl"
import MenuUtils from "./s4n/Menu/utils"

/**
 * Take data from Drupal and build metadata
 * @see: http://soft4net.test/pl/jsonapi/metatag_defaults/metatag_defaults
 * 
 * @param {*} props 
 */
function SEO(props) {
// console.log(props)

  // query data from gatsby-config.js
  let { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            slogan
            description
            author
            siteUrl
          }
        }

        # Get drupal

        # Get drupal/metatag default configuration
        allMetatagDefaultsMetatagDefaults {
          edges {
            node {
              id
              status
              langcode
              label
              tags {
                # basic
                  title
                  description
                  # abstract
                  # keywords
                # advanced
                  canonical_url
                  # cache_control
              }
            }
          }
        }


      }
    `
  )



  let { 
    lang, title, description, meta, // https://github.com/gatsbyjs/gatsby-starter-default/blob/master/src/components/seo.js
    nodeId, abstract, keywords, field_metatag, // Soft4Net
  } = props;

  field_metatag = field_metatag || {}

  const {
    // drupal/metatag basic
      title: drupalMetatagTitle,
      description: drupalMetatagDescription,
      abstract: drupalMetatagAbstract,
      keywords: drupalMetatagKeywords,
    // drupal/metatag advanced
      canonical_url,
      cache_control,
  } = field_metatag;

  title = title || drupalMetatagTitle
  description = description || drupalMetatagDescription
  abstract = abstract || drupalMetatagAbstract
  keywords = keywords || drupalMetatagKeywords



  // process fields
  keywords = keywords && keywords.includes(`,`) ? 
    keywords.split(`,`).map(keyword => keyword.trim()) : keywords ? 
      [keywords.trim()] : [];

  // console.log(keywords)



// Soft4Net START

  // console.log(site)

  const intl = useIntl();
  const t = intl.formatMessage;
  lang = intl.locale;

  // overwrite gatsby-config.js metadata with ones stored in gatsby-plugin-intl translation files: src/intl/*.json, 
  // because i think by default Gatsby does not allow to translate these metadata
  for (let key in site.siteMetadata) {
    const metaAttributeValue = site.siteMetadata[key];
    // console.log(key);
    // console.log(metaAttributeValue);
    const translatedMetadataAttribute = t({ id: `siteMetadata_${key}` });
    if (`siteMetadata_${key}` !== translatedMetadataAttribute) { // check if we have translation available!!!
      site.siteMetadata[key] = translatedMetadataAttribute || metaAttributeValue; 
    }
    // site.siteMetadata[key] = props[key] || t({ id: `siteMetadata_${key}` }) || metaAttributeValue; // DO NOT USE THIS SINCE we should just overwrite site.siteMetadata not passed props params
  }

  // console.log(site.siteMetadata);

  // Add extra meta attributes
  if (Array.isArray(keywords) && keywords.length > 0) {
    meta = meta.concat({
      name: `keywords`,
      content: keywords.join(`, `),
    });
  }
  // @see: https://www.helloimkyle.com/how-to-verify-a-gatsby-site-with-google-webmasters/
  if (`null` !== process.env.GOOGLE_SITE_VERIFICATION && process.env.GOOGLE_SITE_VERIFICATION.length > 0) {
    meta = meta.concat({
      name: 'google-site-verification', 
      content: process.env.GOOGLE_SITE_VERIFICATION,
    });
  }
  // meta = meta.concat({
  //   property: `og:url`,
  //   // content: url,
  // });

  // title = title || site.siteMetadata.title;

  const isHomePage = (nodeId) => {
    if (Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId) {
      return true;
    }

    return false;
  }

  const getDescription = (nodeId) => {
    if (typeof nodeId === `undefined`) {
      return description || site.siteMetadata.description;
    }

    const TRANSLATION_KEY = `siteMetadata_description_drupal_internal__nid_`;
    const translatedMetadataAttribute = t({ id: `${TRANSLATION_KEY}${nodeId}` });

    let metaAttributeValue = null;
    if (`${TRANSLATION_KEY}${nodeId}` !== translatedMetadataAttribute) { // check if we have translation available!!!
      metaAttributeValue = translatedMetadataAttribute; 
    }

    return metaAttributeValue || description;
  }

  // @see: https://www.gatsbyjs.org/packages/gatsby-plugin-canonical-urls/
  const generateLinkCanonical = (nodeId) => {
    const currentLanguage = intl.locale;
    const nodeMenuItemCurrentLocale = MenuUtils.getItemByNodeId(nodeId, currentLanguage);

    if (!nodeMenuItemCurrentLocale) {
      return null;
    }

    // for home page we want canonical like: 
    if (isHomePage(nodeId)) {
      nodeMenuItemCurrentLocale.link.url = ``;
    }

    return (
      <Helmet
        defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
      >
        <link rel="canonical" href={`${site.siteMetadata.siteUrl}/${nodeMenuItemCurrentLocale.langcode}${nodeMenuItemCurrentLocale.link.url}`} />
      </Helmet>
    )
  }

  const generateLinkAlternate = (nodeId) => {
    const defaultLanguage = process.env.SOFT4NET_LOCALE_DEFAULT || `en`;
    const aAvailableLanguages = process.env.SOFT4NET_AVAILABLE_LANGUAGES.split(','); // [ 'pl', 'en' ]
    const nodeMenuItemDefaultLocale = MenuUtils.getItemByNodeId(nodeId, defaultLanguage);

    if (!nodeMenuItemDefaultLocale) {
      return null;
    }

    // for home page we want canonical like: 
    if (isHomePage(nodeId)) {
      nodeMenuItemDefaultLocale.link.url = ``;
    }

    return (
      <Helmet
        defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
      >
        <link rel="alternate" hreflang="x-default" href={`${site.siteMetadata.siteUrl}/${nodeMenuItemDefaultLocale.langcode}${nodeMenuItemDefaultLocale.link.url}`} />
        {aAvailableLanguages.map(locale => {
          const nodeMenuItemInLocale = MenuUtils.getItemByNodeId(nodeId, locale);

          if (!nodeMenuItemInLocale) {
            return null;
          }

          // for home page we want canonical like: 
          if (isHomePage(nodeId)) {
            nodeMenuItemInLocale.link.url = ``;
          }

          return <link rel="alternate" hreflang={locale} href={`${site.siteMetadata.siteUrl}/${nodeMenuItemInLocale.langcode}${nodeMenuItemInLocale.link.url}`} />
        })}
      </Helmet>
    )
  }

  const metaDescription = getDescription(nodeId) || site.siteMetadata.description;
  // const metaDescription = description || site.siteMetadata.description // this was in ORIGINAL code

// Soft4Net STOP

  const defaultTitle = props.defaultTitle ?? site.siteMetadata.title;

  return (
    <React.Fragment>
      <Helmet
        defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
        htmlAttributes={{
          lang,
        }}
        title={title}
        // titleTemplate={`%s | ${site.siteMetadata.title}`}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `facebook-domain-verification`,
            content: `0uappgytr7ipauys0re25kdjmzhotu`,
          },
        ].concat(meta)}
      />
      {generateLinkCanonical(nodeId)}
      {generateLinkAlternate(nodeId)}
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: null,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO