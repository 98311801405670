import { INTL_PRESERVE } from './types';
import { globalHistory } from "@reach/router"

const getCurrentLanguage = () => {
    // console.log(globalHistory.location.pathname)        
    let locale = process.env.SOFT4NET_LOCALE_DEFAULT || `en`;
    if (globalHistory.location.pathname) {
        locale = globalHistory.location.pathname.substring(1, 3);
    }
// console.log(locale)
    return locale;
};

// action creator
export const intlPreserve = (intl) => ({
    type: INTL_PRESERVE, 
    intl: intl || { locale: getCurrentLanguage() },
});