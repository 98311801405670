module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Decolly.pl","short_name":"Decolly","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/custom/logo-favicon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PDLD2PQ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/pozi/CUSTOM/MacOS/ssd_crucial_mx500_1tb/Server/Docker/shared/Gatsby/decolly.pl/src/intl","languages":["pl"],"defaultLanguage":"pl","redirect":true,"redirectComponent":"/home/pozi/CUSTOM/MacOS/ssd_crucial_mx500_1tb/Server/Docker/shared/Gatsby/decolly.pl/src/components/redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"2784318961845288","version":"v8.0","xfbml":true,"cookie":false,"includeInDevelopment":true,"debug":false,"language":"en_US","autoLogAppEvents":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"622901058865315"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
