// THIS IS A PROXY API FOR CENTARRO SO IF THEY UPDATE WE WILL HAVE CHANGES RIGHT AWAY AND WE CAN ALSO ADD OUR MODEL/LOGIC
// CONSIDER ADDING PROXY FOR HttpClient ??

import { jsonapiClient as jsonapiClientCentarro } from './../../ext/utils/api';
import { HttpClient } from '@centarro/js-sdk';

import { client } from './../../../../../apollo/client'
import gql from 'graphql-tag'

// Remove customizations to resource types, @see: https://www.drupal.org/docs/contributed-modules/commerce-api/remove-customizations-to-resource-types START
const HAS_DISABLED_CUSTOMIZATIONS_TO_RESOURCE_TYPES_IN_DRUPAL = true // @see: web/modules/custom/soft4net/src/Soft4NetServiceProvider.php
const MAP_JSONAPI_RESOURCE_PATH_NEW_2_OLD = {
    'products': 'commerce_product',
}

/**
 * An async helper function for making requests to a Drupal backend.
 *
 * @param {string} REACT_APP_API_URL
 *  The base url of the backend (Drupal)
 * @param {string} endpoint
 *  The name of the end point you want to use.
 * @param {Object} [settings={}]
 *  Optional settings.
 * @param {Object} [settings.parameters={}]
 *  Route string construction parameters.
 * @param {Object} [settings.options={}]
 *  HTTP request options.
 * @return {Promise}
 *  Result of the fetch operation.
 */
export async function jsonapiClient(
    REACT_APP_API_URL,
    endpoint,
    {parameters = {}, options = {}} = {},
) {



    // Redirect Response was invoked by Drupal Admin setting for resolving languages: /admin/config/regional/language/detection i disabled my previous default setup URL, was ON now is OFF, and after this there's no redirection!!!
    // const langcode = options.langcode || ``; // Empty langcode will cause Redirect Response that do not pass Authorization header and this ended up in not authenticated JSON:API response!!!
    // const langcode = null; // Empty langcode will cause Redirect Response that do not pass Authorization header and this ended up in not authenticated JSON:API response!!!
    const langcode = `pl`;
    const apiPrefix = langcode ? `/${langcode}/jsonapi` : `/jsonapi`;



    // let authorization = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;
    // if (null === authorization && parameters.authorization) {
    //     authorization = `${parameters.authorization.token_type} ${parameters.authorization.access_token}`;
    // }
    let authorization = null;
    if (parameters.authorization) {
        authorization = `${parameters.authorization.token_type} ${parameters.authorization.access_token}`;
    }

    // console.log(authorization)

    options.headers = options.headers || {};
    const httpClient = new HttpClient(
      REACT_APP_API_URL,
      apiPrefix,
      parameters.cartToken || undefined,
      localStorage.getItem('currentStoreId') || process.env.REACT_APP_STORE_UUID,
      authorization
    );

    let purchasableEntity = parameters.purchasedEntity || null;
    let response = null,
        json = null,
        data = null,
        included = null,
        
        cart = null,
        attributes = null,
        fields = null,
        includes = null,
        body = null
        
        ;

    switch (endpoint) {



        // KEEP TRACK OF THIS CHANGES IN CENTARRO API START !!! we change endpoints naming since we 

        case 'featured_products':
            if (HAS_DISABLED_CUSTOMIZATIONS_TO_RESOURCE_TYPES_IN_DRUPAL) {
                return await httpClient.request(`/${MAP_JSONAPI_RESOURCE_PATH_NEW_2_OLD[`products`]}/simple`, {
                    includes: ['variations', 'variations.images'],
                    fields: {
                        // 'product--simple': ['title', 'variations', 'path'],
                        // 'product-variation--simple': ['resolved_price', 'prices', 'images'],
                        // 'product--clothing': ['title', 'variations', 'path'],
                        // 'product-variation--clothing': ['resolved_price', 'prices', 'images'],

                        // s4n
                        'product--default': ['title', 'variations', 'path'],
                        'product-variation--default': ['resolved_price', 'prices', 'images'],

                        'file--file': ['uri'],
                    },
                    filter: {
                        'special_categories.entity.name': 'Featured'
                    },
                    page: {
                        limit: 6
                    },
                    sort: ['-changed']
                });
            }
        
        case 'catalog_products':
            if (HAS_DISABLED_CUSTOMIZATIONS_TO_RESOURCE_TYPES_IN_DRUPAL) {
                return await httpClient.request(`/${MAP_JSONAPI_RESOURCE_PATH_NEW_2_OLD[`products`]}`, {
                    includes: ['variations', 'variations.images'],
                    fields: {
                        // 'product--simple': ['title', 'variations', 'path'],
                        // 'product-variation--simple': ['resolved_price', 'prices', 'images'],
                        // 'product--clothing': ['title', 'variations', 'path'],
                        // 'product-variation--clothing': ['resolved_price', 'prices', 'images'],

                        // s4n
                        'product--default': ['title', 'variations', 'path'],
                        'product-variation--default': ['resolved_price', 'prices', 'images'],

                        'file--file': ['uri'],
                    },
                    filter: {
                        'product_categories.id': parameters.id
                    },
                    page: {
                        limit: 6
                    },
                    sort: ['title']
                });
            }
        
        case 'product_single':
// console.log(`s4n proxy api case "product_single"`)
            if (HAS_DISABLED_CUSTOMIZATIONS_TO_RESOURCE_TYPES_IN_DRUPAL) {
                const queryInclude = ['variations', 'variations.images', 'special_categories', 'product_categories', 'brand'];
                const queryVariationFields = ['sku', 'price', 'resolved_price', 'images'];
                if (parameters.bundle === 'clothing') {
                    queryInclude.push('variations.attribute_color', 'variations.attribute_size')
                    queryVariationFields.push('attribute_color', 'attribute_size');
                }
                const productFieldsKey = `fields[product--${parameters.bundle}]`;
                const variationFieldsKey = `product-variation--${parameters.bundle}`
                return await httpClient.request(`/${MAP_JSONAPI_RESOURCE_PATH_NEW_2_OLD[`products`]}/${parameters.bundle}/${parameters.id}`, {
                    fields: {
                        'taxonomy_term--product_categories': ['name'],
                        'taxonomy_term--special_categories': ['name'],
                        'taxonomy_term--brands': ['name'],
                        'file--file': ['uri'],
                        [productFieldsKey]: ['title', 'body', 'variations', 'special_categories', 'product_categories', 'brand', 'stores'],
                        [variationFieldsKey]: queryVariationFields
                    },
                    includes: queryInclude
                })
            }

        // KEEP TRACK OF THIS CHANGES IN CENTARRO API STOP !!!



        // @see: https://www.centarro.io/blog/latest-commerce-api-release-adds-support-site-payments
        // for processing payment
        case 'post_checkout':
            // return await httpClient.postCheckout(parameters.cart, parameters.attributes, {
            //     // 'product-variation--simple': ['product_id'],
            //     // 'product-variation--clothing': ['product_id'],

            //     // s4n
            //     'product-variation--default': ['product_id'],

            //     'order-item--product-variation': ['title', 'quantity', 'unit_price', 'total_price', 'purchased_entity', 'order_id'],
            // }, ['payment_gateway', 'payment_method', 'order_id'])


                cart = parameters.cart
                attributes = parameters.attributes
                fields = {
                    // 'product-variation--simple': ['product_id'],
                    // 'product-variation--clothing': ['product_id'],
    
                    // s4n
                    'product-variation--default': ['product_id'],
    
                    'order-item--product-variation': ['title', 'quantity', 'unit_price', 'total_price', 'purchased_entity', 'order_id'],
                }
                includes = ['payment_gateway', 'payment_method', 'order_id']
                body = null


                // console.log(attributes)
                let bodyDefault = {
                    data: {
                        // The document's primary data contains a resource object with a type that cannot be created via this URL. 
                        // Allowed resource types: `commerce_payment--payment_default`, `commerce_payment--payment_manual`
                        type: "commerce_payment--payment_default",
                        // type: `commerce_payment--payment_manual`, 
                        // type: cart.type, 
                        id: cart.id,
                        attributes: attributes
                    }
                }; 
                
                // if (attributes.payment_instrument) {
                //     bodyDefault.data.payment_instrument = attributes.payment_instrument;
                // } else {
                //     bodyDefault.data.attributes = attributes;
                // }

                body = body ? body : bodyDefault; // console.log(body)
                

                json = await httpClient.request(`/checkout/${cart.id}/payment`, { includes, fields }, {
                    method: 'POST',
                    body: JSON.stringify(body),
                });
    
                data = json === null || json === void 0 ? void 0 : json.data;
                included = json === null || json === void 0 ? void 0 : json.included;
                if (!data) {
                    // should never happen.
                    throw new Error('unexpected data format');
                }
                
                return { cart: data, included };
                // return { data, included };






        case 'get_checkout_shipping_methods':
            return await httpClient.getCheckoutShippingMethods(parameters.cart, {
                // 'product-variation--simple': ['product_id'],
                // 'product-variation--clothing': ['product_id'],

                // s4n
                'product-variation--default': ['product_id'],

                'order-item--product-variation': ['title', 'quantity', 'unit_price', 'total_price', 'purchased_entity', 'order_id'],
            }, [])

        case 'get_checkout_payment_approve':
            return await httpClient.getCheckoutPaymentApprove(parameters.cart, {
                // 'product-variation--simple': ['product_id'],
                // 'product-variation--clothing': ['product_id'],

                // s4n
                'product-variation--default': ['product_id'],

                'order-item--product-variation': ['title', 'quantity', 'unit_price', 'total_price', 'purchased_entity', 'order_id'],
            }, ['order_items', 'order_items.purchased_entity'])



        case 'user_register':
            // const {cart: {cartToken, carts}, dispatch} = this.props
            // const cart = carts[0]

            // @todo: maybe refactor and use httpClient.request ??!!
            return await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/user/register`, {
                method: 'POST',
                headers: {
                    // 'Commerce-Cart-Token': parameters.cartToken,
                    // 'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    // 'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                },
                body: JSON.stringify({
                    // To add or update a resource object, the request document's primary data must not be an array.
                    data: {
                        type: 'user--user',
                        attributes: {
                            mail: parameters.formData.mail,
                            name: parameters.formData.name,
                            // @see Drupal: /admin/config/people/accounts
                            // [] Wymagaj weryfikacji adresu e-mail w trakcie tworzenia konta użytkownika (settings option)
                            // Nowi użytkownicy będą musieli potwierdzić adres e-mail zanim będą mogli zalogować się do witryny i zostanie im przydzielone hasło wygenerowane automatycznie. Po wyłączeniu tego ustawienia użytkownicy będą mogli się zalogować natychmiast i wybrać własne hasło podczas rejestracji.
                            pass: parameters.formData.pass,
                        },
                    },
                }),
            })

        case 'user_password_reset': // jsonapi_user_resources.password_reset
            // const {cart: {cartToken, carts}, dispatch} = this.props
            // const cart = carts[0]

            // @todo: maybe refactor and use httpClient.request ??!!
            return await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/user/password/reset`, {
                method: 'POST',
                headers: {
                    // 'Commerce-Cart-Token': parameters.cartToken,
                    // 'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    // 'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                },
                body: JSON.stringify({
                    data: {
                        type: `user--password-reset`,
                        attributes: {
                            mail: parameters.formData.mail,
                            // name: parameters.formData.name,
                            // @see Drupal: /admin/config/people/accounts
                            // [] Wymagaj weryfikacji adresu e-mail w trakcie tworzenia konta użytkownika (settings option)
                            // Nowi użytkownicy będą musieli potwierdzić adres e-mail zanim będą mogli zalogować się do witryny i zostanie im przydzielone hasło wygenerowane automatycznie. Po wyłączeniu tego ustawienia użytkownicy będą mogli się zalogować natychmiast i wybrać własne hasło podczas rejestracji.
                            // pass: parameters.formData.pass,
                        },
                    },
                }),
            })

        case 'user_password_update': // jsonapi_user_resources.password_reset
            // const {cart: {cartToken, carts}, dispatch} = this.props
            // const cart = carts[0]

            const user = null;

            // @todo: maybe refactor and use httpClient.request ??!!
            return await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/user/${user}/password/update`, {
                method: 'POST',
                headers: {
                    // 'Commerce-Cart-Token': parameters.cartToken,
                    // 'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    // 'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                },
                body: JSON.stringify({
                    // To add or update a resource object, the request document's primary data must not be an array.
                    data: {
                        type: 'user--user',
                        attributes: {
                            mail: parameters.formData.mail,
                            name: parameters.formData.name,
                            // @see Drupal: /admin/config/people/accounts
                            // [] Wymagaj weryfikacji adresu e-mail w trakcie tworzenia konta użytkownika (settings option)
                            // Nowi użytkownicy będą musieli potwierdzić adres e-mail zanim będą mogli zalogować się do witryny i zostanie im przydzielone hasło wygenerowane automatycznie. Po wyłączeniu tego ustawienia użytkownicy będą mogli się zalogować natychmiast i wybrać własne hasło podczas rejestracji.
                            pass: parameters.formData.pass,

                            // 'pass' => user_password(),
                            // 'hash' => user_pass_rehash($this->sut, $timestamp),
                            // 'timestamp' => $timestamp,
                        },
                    },
                }),
            })

        // @todo: WHAT IS THE BEST WAY TO GET LOGGED IN USER PROFILE DATA FROM DRUPAL JSON:API?
        case 'user_profile': // jsonapi_user_resources.password_reset





/*
            response = await fetch(`${process.env.REACT_APP_API_URL}/pl/jsonapi/user/user`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                }
            })

            if (!response.ok) {
                throw new Error('unexpected data format');
            }

            json = await response.json();

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            return { data, included };
*/





/*
        json = await httpClient.request(`/user/user`, {});
        data = json === null || json === void 0 ? void 0 : json.data;
        included = json === null || json === void 0 ? void 0 : json.included;

        let authenticatedAsJsonapiEndpoint = null;
        if (json?.links[`authenticated-as`]?.href) {
            // json.links[`authenticated-as`].href === https://api.decolly.pl/pl/jsonapi/user/user/1f9f78c7-9d10-420e-9a55-b35766863848
            authenticatedAsJsonapiEndpoint = json.links[`authenticated-as`].href.split(`jsonapi`)[1];
        }
        // return { data: [], included: [] };

        json = await httpClient.request(authenticatedAsJsonapiEndpoint, {});

        data = json === null || json === void 0 ? void 0 : json.data;
        included = json === null || json === void 0 ? void 0 : json.included;
        if (!data) {
            // should never happen.
            throw new Error('unexpected data format');
        }
        return { data, included };
*/






/*
            http://decolly.test/pl/jsonapi/profile/customer
            via authenticated-as we get:
            http://decolly.test/pl/jsonapi/user/user/b6a87851-ae54-4518-85ab-08742dc46ac7

            http://decolly.test/pl/jsonapi/user/user/
*/
            json = await httpClient.request(`/user/user`, {
                // includes: [
                //    'customer_profiles', 'user_picture',
                // ],
                // fields: {
                //     'user--user': ['attributes', 'relationships'],
                // },
            });

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            return { data, included };
            // if (Array.isArray(data)) {
            //     return { data: data[0], included };
            // }
            // else {
            //     return { data: data, included };
            // }

/*
        This endpoint describes specific newseletter 
        {
            "uuid": "0712c522-d14c-4e5b-9251-02b45ed6f702",
            "langcode": "pl",
            "status": true,
            "dependencies": [],
            "name": "Default newsletter",
            "id": "default",
            "description": "This is an example newsletter. Change it.",
            "format": "plain",
            "priority": 0,
            "receipt": true,
            "from_name": "Soft4Net",
            "subject": "[[simplenews-newsletter:name]] [node:title]",
            "from_address": "info@soft4net.pl",
            "hyperlinks": false,
            "allowed_handlers": [],
            "new_account": "none",
            "opt_inout": "double",
            "weight": 0
        }
         
        @todo: change to jsonapi endpoint: 
        /jsonapi/simplenews_newsletter/simplenews_newsletter => return array of all newsletters
        /jsonapi/simplenews_newsletter/simplenews_newsletter/0712c522-d14c-4e5b-9251-02b45ed6f702 => return object with specific newsletter (in this case default newsletter)
*/
        case 'simplenews_newsletter': // this is REST endpoint

            let simplenews_newsletter = parameters.simplenews_newsletter;

            response = await fetch(`${process.env.REACT_APP_API_URL}/entity/simplenews_newsletter/${simplenews_newsletter}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                }
            })
            let simplenewsNewsletterDefault = await response.json();
            if (!simplenewsNewsletterDefault) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            return { simplenewsNewsletter: simplenewsNewsletterDefault }

        case 'simplenews_subscriber_get_id': // this is REST endpoint
            let APOLLO_QUERY = gql`
            { 
                simplenewsSubscriberQuery (
                    filter: {
                        conditions: [
                            {operator: EQUAL, field: "mail", value: "${parameters.mail}"}
                        ]
                    }
                ) {
                    entities {
                        entityId
                    }
                }
            }`

// console.log(APOLLO_QUERY.loc.source.body)

            let result = null;
            try {
                result = await client.query({
                    query: APOLLO_QUERY,

                    /**
                    * FIX DRUPAL GRAPHQL SPECIFICATION THAT IS NOT RETURNS NULL ON EMPTY FIELD!!!
                    * @see: https://github.com/apollographql/apollo-client/issues/4267
                    * Filter out empty products ex. { __typename: "CommerceProductTest" } and no other properties!
                    */
                    // fetchPolicy: `cache-first`,
                    fetchPolicy: `no-cache`,

                }); // {data: {…}, loading: false, networkStatus: 7, stale: false}
                // const { data, loading, error } = await client.query({query: APOLLO_QUERY});
            } catch (error) {
                console.log(error)
            }
/*
            {
                "data": {
                    "simplenewsSubscriberQuery": {
                        "entities": [
                            {
                                "entityId": "24"
                            }
                        ]
                    }
                }
            }
*/
            let simplenewsSubscriberEntities = result?.data?.simplenewsSubscriberQuery?.entities;
            let simplenewsSubscriberId = simplenewsSubscriberEntities.length ? simplenewsSubscriberEntities[0]?.entityId : null;

            return simplenewsSubscriberId;

        // (authorization, simplenews_subscriber, method, status, mail, subscriptions, store)
/*
            console.log(simplenewsSubscriber)
            {
                "id": [
                    {
                        "value": 24
                    }
                ],
                "uuid": [
                    {
                        "value": "749f0089-c916-4ebe-a58c-a52e129f7f8a"
                    }
                ],
                "status": [ // status for all subscriptions, whether the subscription is active or blocked "main switch"
                    {
                        "value": true
                    }
                ],
                "mail": [
                    {
                        "value": "mrpozi@gmail.com"
                    }
                ],
                "uid": [],
                "langcode": [
                    {
                        "value": "pl"
                    }
                ],
                "changes": [],
                "created": [
                    {
                        "value": "2020-09-29T13:02:58+00:00",
                        "format": "Y-m-d\\TH:i:sP"
                    }
                ],
                "subscriptions": [
                    {
                        "target_id": "default",
                        "status": 1, // can have many subscriptions, "certain newsletter switch", 0|1|2 => 0 - unsubscribed, 1 - subscribed, 2 - unconfirmed
                        "timestamp": null,
                        "source": null,
                        "target_type": "simplenews_newsletter",
                        "target_uuid": "0712c522-d14c-4e5b-9251-02b45ed6f702"
                    }
                ]
            }
*/
        case 'simplenews_subscriber': // this is REST endpoint
// console.log('parameters: ', parameters)

            let init = {
                method: parameters.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }

            if (parameters.authorization) {
                init.headers['Authorization'] = `${parameters.authorization.token_type} ${parameters.authorization.access_token}`;
            }

            // console.log(init)

            let simplenews_subscriber = parameters.simplenews_subscriber || ``;

            if (null === parameters.simplenews_subscriber && `PATCH` === parameters.method) { // this is the case for authenticated user, when there's no subscription for this user (it has no record in the database yet) then in this case we need to set method to POST (create subscription)
                init.method = 'POST';
            }

            let body = {
                mail: [{
                    value: parameters.mail
                }],
                subscriptions: [{
                    target_id: parameters.subscriptions.target_id,
                }]
            }
            if (null !== parameters.status) {
                body.status = [{
                    value: parameters.status // status for all subscriptions, whether the subscription is active or blocked "main switch"
                }]
            }
            if (typeof parameters.subscriptions.status !== `undefined`) {
                body.subscriptions = [{
                    ...body.subscriptions[0],
                    status: parameters.subscriptions.status, // can have many subscriptions, "certain newsletter switch"
                }]
            }
            if ([`POST`, `PATCH`].includes(parameters.method)) {
                init.body = JSON.stringify(body);
            }
            
// console.log('init: ', init)

            response = await fetch(`${process.env.REACT_APP_API_URL}/entity/simplenews_subscriber/${simplenews_subscriber}`, init);
            let simplenewsSubscriber = await response.json();

            if (!simplenewsSubscriber) {
                // should never happen.
                throw new Error('unexpected data format');
            }

            return { simplenewsSubscriber };

        case 'wishlist_fetch':
            if (null === authorization) {
                return { wishlist: [], included: [] };
            }

            json = await httpClient.request(
                `/commerce_wishlist/default`,
                {
                    /**
                     * includes
                     * @see: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/includes
                     * 
                     * These are relationships fields from JSONAPI response (wishlist_items) and nested relationships fields within relationships fields (wishlist_items.purchased_entity)
                     * http://decolly.test/pl/jsonapi/commerce_wishlist/default - data.relationships.wishlist_items (from data.relationships.wishlist_items.links.related.href we have nested relationships below: ) [wishlist_items]
                     * http://decolly.test/pl/jsonapi/commerce_wishlist/default/a7b76e91-1144-4d33-aa34-57b98b7c77e4/wishlist_items - data.relationships.purchasable_entity [wishlist_items.purchased_entity]
                     * 
                     */
                    includes: [
                        'wishlist_items.purchasable_entity.product_id',
                        // `wishlist_items.purchasable_entity.product_id.variations`,
                    ],

                    /**
                     * fields
                     * 
                     * These are attributes fields from JSONAPI response
                     */
                    fields: {
                        // 'commerce_wishlist_item--commerce_product_variation': ['title'],
                        // 'commerce_product_variation--default': ['title'],
                        // 'commerce_product_variation--phone': ['title'],
                    }
                }
                // ,{
                //     // method: 'GET',

                //     // @see: https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
                //     // headers: headers
                //     headers: {
                //         // 'Commerce-Cart-Token': parameters.cartToken,
                //         'Commerce-Current-Store': process.env.REACT_APP_STORE_UUID,
                //         'Content-Type': 'application/vnd.api+json',
                //         'Accept': 'application/vnd.api+json',
                //         'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                //     }
                // }
            );

            // console.log(json)

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            if (Array.isArray(data)) {
                return { wishlist: data[0], included };
            }
            else {
                return { wishlist: data, included };
            }

        case 'wishlist_add':
            json = await httpClient.request(
                `/wishlist/add`,
                undefined,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        data: [
                            {
                                type: purchasableEntity.type,
                                id: purchasableEntity.id,
                                meta: {
                                    quantity: 1,
                                    wishlist_type: `favorite`,
                                },
                            },
                        ],
                    }),
                },
            );

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            if (Array.isArray(data)) {
                return { wishlist: data[0], included };
            }
            else {
                return { wishlist: data, included };
            }

        case 'wishlist_remove':
            const commerce_wishlist = purchasableEntity.relationships.wishlist_id.data.id;

            json = await httpClient.request(
                `/wishlists/${commerce_wishlist}/items`,
                undefined,
                {
                    method: 'DELETE',
                    body: JSON.stringify({
                        data: [
                            {
                                type: purchasableEntity.type, // "commerce_wishlist_item--commerce_product_variation"
                                id: purchasableEntity.id,
                            },
                        ],
                    }),
                },
            );

            // @todo: json is an empty object = {}, there's no response body or data so no need to process, we can only debug request errors...

        case 'profile_fetch':
                if (null === authorization) {
                    return { data: [], included: [] };
                }
        
                json = await httpClient.request(
                    `/profile/customer`,
                );
    
                data = json === null || json === void 0 ? void 0 : json.data;
                included = json === null || json === void 0 ? void 0 : json.included;
                if (!data) {
                    // should never happen.
                    throw new Error('unexpected data format');
                }
                // if (Array.isArray(data)) {
                //     return { data: data[0], included };
                // }
                // else {
                //     return { data: data, included };
                // }
                return { data: data, included };
    
        case 'profile_add':
            // @todo: maybe refactor and use httpClient.request ??!!
            return await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/wishlist/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',

                    'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                },
                body: JSON.stringify({
                    data: [
                        {
                            type: purchasableEntity.type, // ?? "commerce_product_variation--default"
                            id: purchasableEntity.id,
                            meta: {
                                quantity: 1,
                                wishlist_type: `favorite`,
                            },
                        },
                    ],
                }),
            })
    
        case 'profile_patch':
            const profileIdToPatch = parameters.profile.id;
/*
            json = await httpClient.request(
                `/profile/customer/${profileIdToPatch}`, 
                undefined,
                {
                    method: 'PATCH',
                    body: JSON.stringify({
                        // To add or update a resource object, the request document's primary data must not be an array.
                        data: {
                            type: `profile--customer`, // "commerce_wishlist_item--commerce_product_variation"
                            id: profileIdToPatch,
                            attributes: parameters.attributes,
                        },
                    }),
                }
            );

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            // if (Array.isArray(data)) {
            //     return { order: data[0], included };
            // }
            // else {
            //     return { order: data, included };
            // }
            return { data: data, included };
*/

            // @todo: maybe refactor and use httpClient.request ??!!
            response = await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/profile/customer/${profileIdToPatch}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',

                    'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                },
                body: JSON.stringify({
                    // To add or update a resource object, the request document's primary data must not be an array.
                    data: {
                        type: `profile--customer`, // "commerce_wishlist_item--commerce_product_variation"
                        id: profileIdToPatch,
                        attributes: parameters.attributes,
                    },
                }),
            })

            json = await response.json();
            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            // if (Array.isArray(data)) {
            //     return { order: data[0], included };
            // }
            // else {
            //     return { order: data, included };
            // }
            return { data: data, included };

        case 'profile_remove':
            const profileIdToRemove = parameters.profile.id;

            // @todo: maybe refactor and use httpClient.request ??!!
            return await fetch(`${process.env.REACT_APP_API_URL}/jsonapi/profile/customer/${profileIdToRemove}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',

                    'Authorization': `${parameters.authorization.token_type} ${parameters.authorization.access_token}`,
                },
            })

        case 'order_fetch':
            if (null === authorization) {
                return { order: [], included: [] };
            }

            json = await httpClient.request(`/commerce_order/default`, {
                includes: [
                    'order_items.purchased_entity.product_id',
                ],
                fields: {
                    // 'commerce_wishlist_item--commerce_product_variation': ['title'],
                    // 'commerce_product_variation--default': ['title'],
                    // 'commerce_product_variation--phone': ['title'],
                },
                // filter: {
                //     'product_categories.id': parameters.id
                // },
                // page: {
                //     limit: 10
                // },
                sort: ['-placed'] // sort DESC because of "-" sign"
            });

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            // if (Array.isArray(data)) {
            //     return { order: data[0], included };
            // }
            // else {
            //     return { order: data, included };
            // }
            return { order: data, included };

        case 'order_item_fetch':
            if (null === authorization) {
                return { order: [], included: [] };
            }

            json = await httpClient.request(`/commerce_order_item/default`, {
                includes: [
                    // 'purchased_entity.product_id',
                ],
                fields: {
                    // 'commerce_wishlist_item--commerce_product_variation': ['title'],
                    // 'commerce_product_variation--default': ['title'],
                    // 'commerce_product_variation--phone': ['title'],
                }
            });

            data = json === null || json === void 0 ? void 0 : json.data;
            included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                // should never happen.
                throw new Error('unexpected data format');
            }
            if (Array.isArray(data)) {
                return { order: data[0], included };
            }
            else {
                return { order: data, included };
            }

        case `getHttpClient`:
            return httpClient;
      
        default:
// console.log(`s4n proxy api case "default"`)
            let payload = null;
            try {
                payload = await jsonapiClientCentarro(
                    `${REACT_APP_API_URL}/${langcode}`,
                    endpoint, 
                    {
                        parameters, 
                        options
                    }
                )
            } catch (error) {
                console.log(error)
            }
            return payload;
            
    }
}






// node_modules/@centarro/js-sdk/lib/http_client.js | s4n START
/*
    constructor(apiUrl, apiPrefix, cartToken, storeId, authorization) {
        // remove any trailing slashes.
        apiUrl = apiUrl.replace(/\/$/, '');
        if (apiPrefix.substring(0, 1) !== '/') {
            throw new Error('apiPrefix must begin with "/"');
        }
        this.apiPrefix = apiPrefix;
        this.apiUrl = apiUrl;
        // ensure a cart token.
        this.cartToken = cartToken || utils_1.generateCartToken();
        this.storeId = storeId;



// s4n START
        if (!authorization) {
            const token = localStorage.getItem('drupal-oauth-token') !== null ? JSON.parse(localStorage.getItem('drupal-oauth-token')) : null;
            if (token) {
                authorization = `${token.token_type} ${token.access_token}`;
            }
        }
// s4n STOP



        this.authorization = authorization;
    }

// s4n START
    postCheckout(cart, attributes, fields, includes = ['payment_gateway', 'payment_method', 'order_id'], body = null) {

        // console.log(attributes)
        let bodyDefault = {
        data: {
            // The document's primary data contains a resource object with a type that cannot be created via this URL. 
            // Allowed resource types: `commerce_payment--payment_default`, `commerce_payment--payment_manual`
            type: "commerce_payment--payment_default",
            // type: `commerce_payment--payment_manual`, 
            // type: cart.type, 
            id: cart.id,
            attributes: attributes
        }
        }; 
        
        // if (attributes.payment_instrument) {
        //     bodyDefault.data.payment_instrument = attributes.payment_instrument;
        // } else {
        //     bodyDefault.data.attributes = attributes;
        // }

        body = body ? body : bodyDefault; // console.log(body)
        
        return __awaiter(this, void 0, void 0, function* () {
            const json = yield this.request(`/checkout/${cart.id}/payment`, { includes, fields }, {
                method: 'POST',
                body: JSON.stringify(body),
            });
            const data = json === null || json === void 0 ? void 0 : json.data;
            const included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                throw new Error('unexpected data format');
            }
            return { cart: data, included };
        });
    }

    getCheckoutShippingMethods(cart, fields, includes = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const json = yield this.request(`/checkout/${cart.id}/shipping-methods`, {
                includes,
                fields,
            });
            const data = json === null || json === void 0 ? void 0 : json.data;
            const included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                throw new Error('unexpected data format');
            }
            return { cart: data, included };
        });
    }

    getCheckoutPaymentApprove(cart, fields, includes = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const json = yield this.request(`/checkout/${cart.id}/payment/approve`, {
                includes,
                fields,
            });
            const data = json === null || json === void 0 ? void 0 : json.data;
            const included = json === null || json === void 0 ? void 0 : json.included;
            if (!data) {
                throw new Error('unexpected data format');
            }
            return { cart: data, included };
        });
    }
// s4n STOP

*/
// node_modules/@centarro/js-sdk/lib/http_client.js | s4n STOP