// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-checkout-iframe-js": () => import("./../../../src/pages/checkout-iframe.js" /* webpackChunkName: "component---src-pages-checkout-iframe-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-user-commerce-address-index-js": () => import("./../../../src/pages/user/commerce/address/index.js" /* webpackChunkName: "component---src-pages-user-commerce-address-index-js" */),
  "component---src-pages-user-commerce-orders-index-js": () => import("./../../../src/pages/user/commerce/orders/index.js" /* webpackChunkName: "component---src-pages-user-commerce-orders-index-js" */),
  "component---src-pages-user-commerce-wishlist-index-js": () => import("./../../../src/pages/user/commerce/wishlist/index.js" /* webpackChunkName: "component---src-pages-user-commerce-wishlist-index-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-login-index-js": () => import("./../../../src/pages/user/login/index.js" /* webpackChunkName: "component---src-pages-user-login-index-js" */),
  "component---src-pages-user-login-register-index-js": () => import("./../../../src/pages/user/login-register/index.js" /* webpackChunkName: "component---src-pages-user-login-register-index-js" */),
  "component---src-pages-user-newsletter-index-js": () => import("./../../../src/pages/user/newsletter/index.js" /* webpackChunkName: "component---src-pages-user-newsletter-index-js" */),
  "component---src-pages-user-newsletter-unsubscribed-index-js": () => import("./../../../src/pages/user/newsletter/unsubscribed/index.js" /* webpackChunkName: "component---src-pages-user-newsletter-unsubscribed-index-js" */),
  "component---src-pages-user-password-reset-index-js": () => import("./../../../src/pages/user/password/reset/index.js" /* webpackChunkName: "component---src-pages-user-password-reset-index-js" */),
  "component---src-pages-user-profile-index-js": () => import("./../../../src/pages/user/profile/index.js" /* webpackChunkName: "component---src-pages-user-profile-index-js" */),
  "component---src-pages-user-register-index-js": () => import("./../../../src/pages/user/register/index.js" /* webpackChunkName: "component---src-pages-user-register-index-js" */),
  "component---src-templates-category-blog-js": () => import("./../../../src/templates/category/blog.js" /* webpackChunkName: "component---src-templates-category-blog-js" */),
  "component---src-templates-category-product-js": () => import("./../../../src/templates/category/product.js" /* webpackChunkName: "component---src-templates-category-product-js" */),
  "component---src-templates-commerce-product-js": () => import("./../../../src/templates/commerce/product.js" /* webpackChunkName: "component---src-templates-commerce-product-js" */),
  "component---src-templates-node-blog-js": () => import("./../../../src/templates/node/blog.js" /* webpackChunkName: "component---src-templates-node-blog-js" */),
  "component---src-templates-node-page-js": () => import("./../../../src/templates/node/page.js" /* webpackChunkName: "component---src-templates-node-page-js" */),
  "component---src-templates-pagination-blog-js": () => import("./../../../src/templates/pagination/blog.js" /* webpackChunkName: "component---src-templates-pagination-blog-js" */),
  "component---src-templates-pagination-product-js": () => import("./../../../src/templates/pagination/product.js" /* webpackChunkName: "component---src-templates-pagination-product-js" */)
}

