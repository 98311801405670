import { handleActions } from 'redux-actions';

export default handleActions({

/*
  USER_REGISTER_STARTED: (state, { payload }) => {
// console.log('USER_REGISTER_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  USER_REGISTER_SUCCEEDED: (state, { payload }) => {
// console.log('USER_REGISTER_SUCCEEDED: ', payload);
    const { order, included } = payload;
    return {
      ...state,
      items: [order],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_order_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  USER_REGISTER_FAILED: (state, { payload }) => {
// console.log('USER_REGISTER_FAILED: ', payload);
    return state;
  },
  USER_REGISTER_ENDED: (state, { payload }) => {
// console.log('USER_REGISTER_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },


  USER_PASSWORD_RESET_STARTED: (state, { payload }) => {
// console.log('USER_PASSWORD_RESET_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  USER_PASSWORD_RESET_SUCCEEDED: (state, { payload }) => {
// console.log('USER_PASSWORD_RESET_SUCCEEDED: ', payload);
    const { order, included } = payload;
    return {
      ...state,
      items: [order],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_order_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  USER_PASSWORD_RESET_FAILED: (state, { payload }) => {
// console.log('USER_PASSWORD_RESET_FAILED: ', payload);
    return state;
  },
  USER_PASSWORD_RESET_ENDED: (state, { payload }) => {
// console.log('USER_PASSWORD_RESET_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },


  USER_PASSWORD_UPDATE_STARTED: (state, { payload }) => {
// console.log('USER_PASSWORD_UPDATE_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  USER_PASSWORD_UPDATE_SUCCEEDED: (state, { payload }) => {
// console.log('USER_PASSWORD_UPDATE_SUCCEEDED: ', payload);
    const { order, included } = payload;
    return {
      ...state,
      items: [order],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_order_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  USER_PASSWORD_UPDATE_FAILED: (state, { payload }) => {
// console.log('USER_PASSWORD_UPDATE_FAILED: ', payload);
    return state;
  },
  USER_PASSWORD_UPDATE_ENDED: (state, { payload }) => {
// console.log('USER_PASSWORD_UPDATE_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },
*/


  USER_LOGIN: (state, { payload }) => {
// console.log('USER_LOGIN');
    return state;
  },

  USER_LOGOUT: (state, { payload }) => {
// console.log('USER_LOGOUT');
    return ({
      ...state,
      user_profile: null,
      profile_customer: null,
    })
  },


//   USER_PROFILE_STARTED: (state, { payload }) => {
// // console.log('USER_PROFILE_STARTED: ', payload);
//     return {
//       ...state,
//       loading: true,
//     };
//   },
  USER_PROFILE_SUCCEEDED: (state, { payload }) => {
// console.log('USER_PROFILE_SUCCEEDED: ', payload);
    const { data } = payload;
    return {
      ...state,
      user_profile: data,
      // included,
    }
  },
  USER_PROFILE_FAILED: (state, { payload }) => {
// console.log('USER_PROFILE_FAILED: ', payload);
    return state;
  },
//   USER_PROFILE_ENDED: (state, { payload }) => {
// // console.log('USER_PROFILE_ENDED: ', payload);
//     return {
//       ...state,
//       loading: false,
//     };
//   },



  SIMPLENEWS_NEWSLETTER_SUCCEEDED: (state, { payload }) => {
// console.log('SIMPLENEWS_NEWSLETTER_SUCCEEDED: ', payload);
    const { simplenewsNewsletter } = payload;
    return {
      ...state,
      simplenews_newsletter: simplenewsNewsletter,
    }
  },

  SIMPLENEWS_SUBSCRIBER_SUCCEEDED: (state, { payload }) => {
// console.log('SIMPLENEWS_SUBSCRIBER_SUCCEEDED: ', payload);
    const { simplenewsSubscriber } = payload;
    return {
      ...state,
      simplenews_subscriber: simplenewsSubscriber,
    }
  },

  SIMPLENEWS_SUBSCRIBER_FAILED: (state, { payload }) => {
// console.log('SIMPLENEWS_SUBSCRIBER_FAILED: ', payload);
    return state;
  },




  PROFILE_FETCH_SUCCEEDED: (state, { payload }) => {
// console.log('PROFILE_FETCH_SUCCEEDED: ', payload);
    const { data } = payload;
    return {
      ...state,
      profile_customer: data,
      // included,
    }
  },

  PROFILE_ADD_SUCCEEDED: (state, { payload }) => {
// console.log('PROFILE_ADD_SUCCEEDED: ', payload);
    const { data } = payload;
    return {
      ...state,
      profile_customer: data,
    }
  },

  PROFILE_PATCH_SUCCEEDED: (state, { payload }) => {
    // console.log('PROFILE_PATCH_SUCCEEDED: ', payload);
    const { data } = payload;
    return {
      ...state,
      profile_customer: data,
      // included,
    }
  },

  PROFILE_PATCH_FAILED: (state, { payload }) => {
// console.log('PROFILE_PATCH_FAILED: ', payload);
  return state;
  },



}, {
  loading: false,
  
  user_profile: null, // this hold basic user profile data (user name, user mail, account created)
  profile_customer: null, // this holds defined addresses profiles
  simplenews_subscriber: null,
  simplenews_newsletter: null,

  // included: [],
  errorMessage: null,
})
