import { handleActions } from 'redux-actions';

import _ from 'lodash' // for selector
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk" // for selector

export default handleActions({
  WISHLIST_FETCH_STARTED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_STARTED: ', payload);
    return {
      ...state,
      loading: true,
    };
  },
  WISHLIST_FETCH_SUCCEEDED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_SUCCEEDED: ', payload);
    const { wishlist, included } = payload;
    return {
      ...state,
      items: [wishlist],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_wishlist_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  WISHLIST_FETCH_FAILED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_FAILED: ', payload);
    return state;
  },
  WISHLIST_FETCH_ENDED: (state, { payload }) => {
// console.log('WISHLIST_FETCH_ENDED: ', payload);
    return {
      ...state,
      loading: false,
    };
  },

  WISHLIST_ADD_STARTED: (state, { payload }) => {
    return {
      ...state,
      loading: true,
    };
  },
  WISHLIST_ADD_SUCCEEDED: (state, { payload }) => {
    const { wishlist, included } = payload;
    return {
      ...state,
      items: [wishlist],
      included,
      itemCount: included ? included.filter(item => item.type.indexOf('commerce_wishlist_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  WISHLIST_ADD_ENDED: (state, { payload }) => {
    return {
      ...state,
      loading: false,
    };
  },
  WISHLIST_ADD_FAILED: (state, { payload }) => {
    // @todo get the error from the error payload.
    console.log('Sorry, the product could not be added to the wishlist.')
    return state;
  },

  WISHLIST_UPDATE_ITEM_STARTED: state => {
    return {
      ...state,
      loading: true,
    }
  },
  WISHLIST_UPDATE_ITEM_SUCCEEDED: (state, { payload }) => {
    const { wishlist, included } = payload;
    return {
      ...state,
      items: [wishlist],
      included,
      itemCount: payload.included ? payload.included.filter(item => item.type.indexOf('commerce_wishlist_item--commerce_product_variation') === 0).reduce((previousValue, currentValue) => {return previousValue + parseInt(currentValue.attributes.quantity)}, 0) : 0
    }
  },
  WISHLIST_UPDATE_ITEM_ENDED: (state, { payload }) => {
    return {
      ...state,
      loading: false,
    };
  },
  WISHLIST_UPDATE_ITEM_FAILED: (state, { payload }) => {
    // console.log(payload);
    return state;
  },
}, {
  loading: false,
  items: [],
  included: [],
  itemCount: 0,
  errorMessage: null,
})



// selectors
export const selectWishlistItemProductVariations = (state) => { // this state is just wishlist state part so here state === state.shopReducers.wishlist, this param can be called as well "wishlist" 
    const { items, included } = state; // === wishlist

// console.log('included: ', included)
    let wishlistItems = [];
// console.log('items: ', items) // "commerce_wishlist--default"
    if (
        !_.isEmpty(items) && !_.isEmpty(items[0])
        && included
    ) { // if no items on list then included === undefined
        const mappedIncludes = getMappedIncludes(included);
// console.log(mappedIncludes)
        const wishlist_items = getRelationshipFromMappedIncludes(items[0], 'wishlist_items', mappedIncludes) || undefined;

        if (wishlist_items) {
// console.log('wishlist_items: ', wishlist_items)
          wishlistItems = wishlist_items.map(item => {
              const purchasableEntity = getRelationshipFromMappedIncludes(item, 'purchasable_entity', mappedIncludes); // type: 'commerce_product_variation--default' | 'commerce_product_variation--phone'
              const product = getRelationshipFromMappedIncludes(purchasableEntity, 'product_id', mappedIncludes); // type: 'commerce_product--default' | 'commerce_product--phone'
              // const variation = getRelationshipFromMappedIncludes(product, 'variations', mappedIncludes);

              return {
                purchasableEntityId: purchasableEntity.id,
                data: {
                  'commerce_product--default': product,
                  'commerce_product_variation--default': purchasableEntity, // === variation[0]
                  'commerce_wishlist_item--commerce_product_variation': item,
                }
              };
          })
        }
    }

// console.log(wishlistItems)

    return wishlistItems;
} 